import BillsOutlined from 'assets/icons/Bills-Outlined.svg'
import ManualJournalOutlined from 'assets/icons/ManualJornal-Outlined.svg'
import ReportsOutlined from 'assets/icons/Reports-Outlined.svg'
import CustomersOutlined from 'assets/icons/Customers-Outlined.svg'
import ProductsOutlined from 'assets/icons/Products-Outlined.svg'
import TasksOutlined from 'assets/icons/Tasks-Outlined.svg'
import CustomerReceiptsOutlined from 'assets/icons/Cutsomer Receipts-Outlined.svg'
import ProjectsOutlined from 'assets/icons/Projects-Outlined.svg'
import VendorReceiptsOutlined from 'assets/icons/Vendor Receipts-Outlined.svg'
import FolderOpenOutlined from 'assets/icons/FolderOpen-r.svg'
import DimensionSettingsOutlined from 'assets/icons/dimension_settings.svg'
import PurchasesOrdersOutlined from 'assets/icons/Purchases Orders.svg'
import VendorsOutlined from 'assets/icons/Vendors-Outlined.svg'
import InvoiceOutlined from 'assets/icons/Inovice-Outlined.svg'
import QuotationsOutlined from 'assets/icons/Quotations-Outlined.svg'
import DeductionOutlined from 'assets/icons/Deductions-Outlined.svg'
import LoanOutlined from 'assets/icons/Loan-Outlined.svg'
import SoundOutlined from 'assets/icons/SoundOutlined.svg'
import QFill from 'assets/icons/qFilled.svg'
import DisposalOutlined from 'assets/icons/disposal.svg'
import Budgeting from 'assets/icons/Budgeting-icon.svg'
import CurrencyIcon from 'assets/icons/currencyMenu.svg'
import SetupService from 'assets/icons/Setup_Service.svg'
import Q_Academy from 'assets/icons/Q_Academy.svg'

import {DashboardOutlined, DollarOutlined, TableOutlined, SettingOutlined, RocketOutlined, UserOutlined, FileProtectOutlined, FileAddOutlined, EditOutlined, PaperClipOutlined, QuestionCircleOutlined, EnvironmentOutlined, InsertRowBelowOutlined, FallOutlined, ToolOutlined, BarChartOutlined, ApartmentOutlined, GiftOutlined, SyncOutlined} from '@ant-design/icons';
import QIcon from 'components/layout/QIcon';

export const iconMap = new Map<string, React.ReactNode>([
  ['sidepanel_dashboards', <DashboardOutlined key="ICON" />],
  ['sidepanel_advanced_accounting_portal', <DashboardOutlined key="ICON" />],
  ['sidepanel_sales', <DollarOutlined key="ICON" />],
  ['sidepanel_sales__customers', <QIcon key="ICON" component={CustomersOutlined} />],
  ['sidepanel_sales__quotations', <QIcon key="ICON" component={QuotationsOutlined} />],
  ['sidepanel_sales__invoices', <QIcon key="ICON" component={InvoiceOutlined} />],
  ['sidepanel_sales__customer_receipts', <QIcon key="ICON" component={CustomerReceiptsOutlined} />],
  ['sidepanel_purchases', <QIcon key="ICON" component={PurchasesOrdersOutlined} />],
  ['sidepanel_purchases__vendors', <QIcon key="ICON" component={VendorsOutlined} />],
  ['sidepanel_purchases__purchase_orders', <QIcon key="ICON" component={PurchasesOrdersOutlined} />],
  ['sidepanel_purchases__bills', <QIcon key="ICON" component={BillsOutlined} />],
  ['sidepanel_purchases__simple_bills', <QIcon key="ICON" component={BillsOutlined} />],
  ['sidepanel_purchases__vendor_receipts', <QIcon key="ICON" component={VendorReceiptsOutlined} />],
  ['sidepanel_products', <QIcon key="ICON" component={ProductsOutlined} />],
  ['sidepanel_products__products', <QIcon key="ICON" component={ProductsOutlined} />],
  ['sidepanel_products__inventories', <EnvironmentOutlined key="ICON" />],
  ['sidepanel_products__productions', <InsertRowBelowOutlined key="ICON" />],
  ['sidepanel_fixed_assets', <TableOutlined key="ICON" />],
  ['sidepanel_fixed_assets__fixed_assets', <TableOutlined key="ICON" />],
  ['sidepanel_fixed_assets__depreciations', <FallOutlined key="ICON" />],
  ['sidepanel_fixed_assets__disposals', <QIcon key="ICON" component={DisposalOutlined} />],
  ['sidepanel_payrolls', <QIcon key="ICON" component={FolderOpenOutlined} />],
  ['sidepanel_payrolls__payroll_activate', <ToolOutlined key="ICON" />],
  ['sidepanel_payrolls__onboarding_hr', <QIcon key="ICON" component={SoundOutlined} />],
  ['sidepanel_payrolls__employees', <QIcon key="ICON" component={CustomersOutlined} />],
  ['sidepanel_payrolls__payrolls', <QIcon key="ICON" component={FolderOpenOutlined} />],
  ['sidepanel_payrolls__loans', <QIcon key="ICON" component={LoanOutlined} />],
  ['sidepanel_payrolls__bonuses', <GiftOutlined key="ICON" />],
  ['sidepanel_payrolls__deductions', <QIcon key="ICON" component={DeductionOutlined} />],
  ['sidepanel_accounting', <QIcon key="ICON" component={FolderOpenOutlined} />],
  ['sidepanel_accounting__easy_entries', <QIcon key="ICON" component={ManualJournalOutlined} />],
  ['sidepanel_accounting__manual_journal_entries', <QIcon key="ICON" component={ManualJournalOutlined} />],
  ['sidepanel_accounting__accounts', <BarChartOutlined key="ICON" />],
  ['sidepanel_accounting__audit_processes', <ApartmentOutlined key="ICON" />],
  ['sidepanel_accounting__budgets', <QIcon key="ICON" component={Budgeting} />],
  ['sidepanel_accounting__recurring_transactions', <SyncOutlined key="ICON" />],
  ['sidepanel_projects_tasks', <QIcon key="ICON" component={TasksOutlined} />],    
  ['sidepanel_projects_tasks__projects', <QIcon key="ICON" component={ProjectsOutlined} />],
  ['sidepanel_projects_tasks__tasks', <QIcon key="ICON" component={TasksOutlined} />],
  ['sidepanel_reports', <QIcon key="ICON" component={ReportsOutlined} />],
  ['sidepanel_qoyod_services', <QIcon key="ICON" component={QFill} />],
  ['sidepanel_qoyod_services__additional_services', <QIcon key="ICON" component={SetupService} />],
  ['sidepanel_qoyod_services__qoyod_academy', <QIcon key="ICON" component={Q_Academy} />],
  ['sidepanel_qoyod_services__bookkeeping', <QIcon key="ICON" component={QFill} />],
  ['sidepanel_settings', <SettingOutlined key="ICON" />],
  ['sidepanel_settings__general_settings', <SettingOutlined key="ICON" />],
  ['sidepanel_settings__dimensions', <QIcon key="ICON" component={DimensionSettingsOutlined} />],
  ['sidepanel_settings__subscriptions', <SettingOutlined key="ICON" />],
  ['sidepanel_settings__integrations', <RocketOutlined key="ICON" />],
  ['sidepanel_settings__taxes', <SettingOutlined key="ICON" />],
  ['sidepanel_settings__payroll_settings', <SettingOutlined key="ICON" />],
  ['sidepanel_settings__users', <UserOutlined key="ICON" />],
  ['sidepanel_settings__payment_terms', <FileProtectOutlined key="ICON" />],
  ['sidepanel_settings__custom_fields', <FileAddOutlined key="ICON" />],
  ['sidepanel_settings__edit_profile', <EditOutlined key="ICON" />],
  ['sidepanel_settings__attachments', <PaperClipOutlined key="ICON" />],
  ['sidepanel_show_me_how', <QuestionCircleOutlined key="ICON" />],
  ['sidepanel_knowledge_base', <QuestionCircleOutlined key="ICON" />],
  ['sidepanel_qlend', <QIcon key="ICON" component={QFill} />],
  ['sidepanel_qtahseel', <QIcon key="ICON" component={QFill} />],
  ['sidepanel_settings__currencies', <QIcon key="ICON" component={CurrencyIcon} /> ]
])

export const labelMap = new Map<string, string>([
  ['sidepanel_dashboards', 'activerecord.attributes.layout.dashboard'],
  ['sidepanel_advanced_accounting_portal', 'activerecord.attributes.side_menu.advanced_accountant_portal'],
  ['sidepanel_sales', 'activerecord.attributes.layout.sales'],
  ['sidepanel_sales__customers', 'activerecord.attributes.layout.customers'],
  ['sidepanel_sales__quotations', 'activerecord.attributes.layout.quotations'],
  ['sidepanel_sales__invoices', 'activerecord.attributes.layout.invoices'],
  ['sidepanel_sales__customer_receipts', 'activerecord.attributes.layout.customer_receipts'],
  ['sidepanel_purchases', 'activerecord.attributes.layout.purchases'],
  ['sidepanel_purchases__vendors', 'activerecord.attributes.layout.vendors'],
  ['sidepanel_purchases__purchase_orders', 'activerecord.attributes.layout.orders'],
  ['sidepanel_purchases__bills', 'activerecord.attributes.layout.bills'],
  ['sidepanel_purchases__simple_bills', 'activerecord.attributes.layout.simple_bills'],
  ['sidepanel_purchases__vendor_receipts', 'activerecord.attributes.layout.vendor_receipts'],
  ['sidepanel_products', 'activerecord.attributes.layout.products'],
  ['sidepanel_products__products', 'activerecord.attributes.layout.products'],
  ['sidepanel_products__inventories', 'activerecord.attributes.layout.locations'],
  ['sidepanel_products__productions', 'activerecord.attributes.layout.productions'],
  ['sidepanel_fixed_assets', 'activerecord.attributes.layout.fixed_assets'],
  ['sidepanel_fixed_assets__fixed_assets', 'activerecord.attributes.layout.fixed_assets'],
  ['sidepanel_fixed_assets__depreciations', 'activerecord.attributes.fixed_asset.depreciation'],
  ['sidepanel_fixed_assets__disposals', 'activerecord.attributes.tenant_role.disposals'],
  ['sidepanel_payrolls', 'activerecord.attributes.layout.human_resources'],
  ['sidepanel_payrolls__payroll_activate', 'activerecord.attributes.layout.onboarding.activate'],
  ['sidepanel_payrolls__onboarding_hr', 'activerecord.attributes.layout.onboarding.onboarding'],
  ['sidepanel_payrolls__employees', 'activerecord.attributes.layout.employees'],
  ['sidepanel_payrolls__payrolls', 'activerecord.attributes.layout.payrolls'],
  ['sidepanel_payrolls__loans', 'activerecord.attributes.layout.loans'],
  ['sidepanel_payrolls__bonuses', 'activerecord.attributes.layout.bonuses'],
  ['sidepanel_payrolls__deductions', 'activerecord.attributes.layout.deductions'],
  ['sidepanel_accounting', 'activerecord.attributes.layout.accounting'],
  ['sidepanel_accounting__easy_entries', 'activerecord.attributes.layout.easy_entries'],
  ['sidepanel_accounting__manual_journal_entries', 'activerecord.attributes.layout.manual_journal_entries'],
  ['sidepanel_accounting__accounts', 'activerecord.attributes.layout.chart_of_accounts'],
  ['sidepanel_accounting__audit_processes', 'audit_process.menu'],
  ['sidepanel_accounting__budgets', 'budgets.name'],
  ['sidepanel_accounting__recurring_transactions', 'recurring_transactions.title'],
  ['sidepanel_projects_tasks', 'templates.project_and_task'],
  ['sidepanel_projects_tasks__projects', 'templates.projects.projects'],
  ['sidepanel_projects_tasks__tasks', 'templates.tasks.tasks'],
  ['sidepanel_reports', 'activerecord.attributes.layout.reports'],
  ['sidepanel_qoyod_services', 'activerecord.attributes.layout.professional_services'],
  ['sidepanel_qoyod_services__additional_services', 'activerecord.attributes.layout.setup_service'],
  ['sidepanel_qoyod_services__qoyod_academy', 'activerecord.attributes.layout.qoyod_academy'],
  ['sidepanel_qoyod_services__bookkeeping', 'activerecord.attributes.layout.bookkeeping'],
  ['sidepanel_settings', 'activerecord.attributes.layout.settings'],
  ['sidepanel_settings__general_settings', 'activerecord.attributes.layout.general_settings'],
  ['sidepanel_settings__dimensions', 'activerecord.attributes.dimension.settings'],
  ['sidepanel_settings__subscriptions', 'activerecord.attributes.layout.subscription_settings'],
  ['sidepanel_settings__integrations', 'templates.integration'],
  ['sidepanel_settings__taxes', 'activerecord.attributes.tax.layout.index'],
  ['sidepanel_settings__payroll_settings', 'activerecord.attributes.layout.human_resources_settings'],
  ['sidepanel_settings__users', 'activerecord.attributes.layout.users'],
  ['sidepanel_settings__payment_terms', 'activerecord.attributes.layout.payment_terms'],
  ['sidepanel_settings__custom_fields', 'activerecord.attributes.layout.custom_fields'],
  ['sidepanel_settings__edit_profile', 'activerecord.attributes.layout.edit_profile'],
  ['sidepanel_settings__attachments', 'activerecord.attributes.layout.attachments'],
  ['sidepanel_show_me_how', 'activerecord.attributes.help_content.btn_H'],
  ['sidepanel_knowledge_base', 'frontend.tooltips.on_boarding.knowledge_base'],
  ['sidepanel_qlend', 'activerecord.attributes.layout.qoyod_lend'],
  ['sidepanel_qtahseel', 'activerecord.attributes.layout.qoyod_tahseel'],
  ['sidepanel_settings__currencies', 'tenant.multi_currencies.title']
])

export const tagMap = new Map([
  ['beta', {
      label: 'activerecord.attributes.fixed_asset.beta',
      color: 'gold'
  }],
  ['new', {
      label: 'status.new',
      color: 'orange'
  }],
  ['pending', {
      label: 'status.pending',
      color: 'blue'
  }],
  ['soon', {
      label: 'status.soon',
      color: 'blue'
  }],
  ['available', {
      label: 'status.available',
      color: 'green'
  }]
])